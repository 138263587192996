import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Discovery
                                </Link>
                            </h3>
                            <p>Most of the data scientist's day is not spent training the machine learning models, but finding and preparing the data. We help to organize the data in this process with best practices and tools.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-details">
                                Processing
                                </Link>
                            </h3>

                            <p>Starting with Analysis, Mapping Specifications, ETL Development, Orchestration and Scheduling, the entire ETL Process is covered for effectiveness. We consider cost, performance and scalability.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Science & Analytics
                                </Link>
                            </h3>

                            <p>We support the entire process of data science & analytics from data identification, cleansing, model creations, until training and deployment.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-details-testing">
                                Testing Frameworks
                                </Link>
                            </h3>

                            <p>Part of any project the testing phase is one of the most important ones to ensure that all business logics are covered.</p>
                           
                            <Link className="view-details-btn" to="/service-details-testing">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details-abinitio">
                                AbInitio
                                </Link>
                            </h3>

                            <p>Full fledge AbInitio modules stack support, from GDE for data processing, MHUB and Data Catalog for Governance and Lineage implementations, Express>IT and BRE for the various business rules and data quality until Continuous>Flows and Control>Center for orchestration and scheduling.</p>
                            
                            <Link className="view-details-btn" to="/service-details-abinitio">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne